import React, { useState, useEffect } from "react";
import "../../Core/Styles/Style.css";
import { useNavigate, useLocation } from "react-router-dom";
import { ImageUtils } from "../../Utils/imageUtils";
import $ from "jquery";
import jQuery from "jquery";

function Nav() {
  function navbgchange() {
    document.getElementById("navbarSection").style.backgroundColor = "white";
  }

  var JQ = jQuery.noConflict()

  JQ(document).ready(function () {
    JQ('.navbar-toggler').on('click', function () {
      // Add or remove a class to change the background color
      JQ('#navbarSection').toggleClass('white-bg');
    });
  });

  const navigate = useNavigate();
  const location = useLocation();
  const [activeSection, setActiveSection] = useState(null);

  const handleItemClick = (section) => {
    console.log("location============", section);
    if (section.redirect == true) {
      navigate(section.link);
    } else if (section.redirect == false) {
      if (location.pathname == "/") {
        // navigate(section.link);
        setActiveSection(section.scroll);
      }
      else {
        navigate(section.link);
        setActiveSection(section.scroll);
      }
    }

  };

  useEffect(() => {
    if (activeSection) {
      const element = document.getElementById(activeSection);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [activeSection]);
  return (
    <div id="navbarSection" className="navbar-fixed-styling-css ">
      <nav id="navbarColor" className="navbar navbar-expand-lg ">
        <div className="container px-0">
          <a
            className="navbar-brand"
            // href="#"
            onClick={() => handleItemClick("home")}
          >
            <img src={ImageUtils.Nav_Logo} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={navbgchange}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse  navbar-collapse" id="navbarNav">
            <ul className="navbar-nav  mx-auto justify-content-between">
              <li className="nav-item dropdown">
                <a
                  id=""
                  className="nav-link dropdown-toggle navbarlinkColor hover-underline-animation"
                  //   href="Technical_Services"
                  //   role="button"
                  //   data-bs-toggle="dropdown"
                  //   aria-expanded="false"
                  onClick={() =>
                    handleItemClick({
                      scroll: "Technical_Services",
                      link: "/",
                      redirect: false,
                    })
                  }
                >
                  Technical Services
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  id=""
                  className="nav-link dropdown-toggle navbarlinkColor hover-underline-animation"
                  //   href="#"
                  //   role="button"
                  //   data-bs-toggle="dropdown"
                  //   aria-expanded="false"
                  onClick={() =>
                    handleItemClick({
                      scroll: "Technologies",
                      link: "/",
                      redirect: false,
                    })
                  }
                >
                  Technologies
                </a>
              </li>

              <li className="nav-item dropdown">
                <a
                  id=""
                  className="nav-link dropdown-toggle navbarlinkColor hover-underline-animation "
                  //   href="Services"
                  //   role="button"
                  //   data-bs-toggle="dropdown"
                  //   aria-expanded="false"
                  onClick={() =>
                    handleItemClick({
                      scroll: "Services",
                      link: "/",
                      redirect: false,
                    })
                  }
                >
                  Services
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  id=""
                  className="nav-link dropdown-toggle navbarlinkColor hover-underline-animation"
                  // href="/#"
                  //   role="button"
                  //   data-bs-toggle="dropdown"
                  //   aria-expanded="false"
                  onClick={() =>
                    handleItemClick({
                      scroll: "process",
                      link: "/",
                      redirect: false,
                    })
                  }
                >
                  Process
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  id=""
                  className="nav-link dropdown-toggle navbarlinkColor hover-underline-animation"
                  //   href="#"
                  //   role="button"
                  //   data-bs-toggle="dropdown"
                  //   aria-expanded="false"
                  onClick={() =>
                    handleItemClick({
                      scroll: "our_team",
                      link: "/",
                      redirect: false,
                    })
                  }
                >
                  Our Team
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  id=""
                  className="nav-link dropdown-toggle navbarlinkColor hover-underline-animation"
                  // href=""
                  //   role="button"
                  //   data-bs-toggle="dropdown"
                  //   aria-expanded="false"
                  onClick={() =>
                    handleItemClick({
                      scroll: "career",
                      link: "/career",
                      redirect: true,
                    })
                  }
                >
                  Career
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  id=""
                  className="nav-link dropdown-toggle navbarlinkColor hover-underline-animation"
                  //   href="#"
                  //   role="button"
                  //   data-bs-toggle="dropdown"
                  //   aria-expanded="false"
                  onClick={() =>
                    handleItemClick({
                      scroll: "Contact_Us",
                      link: "/",
                      redirect: false,
                    })
                  }
                >
                  Contact Us
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  id=""
                  target="_blank"
                  href="https://institute.KamrinaaTechnologies.com/"
                  className="nav-link dropdown-toggle navbarlinkColor hover-underline-animation"
                  //   role="button"
                  //   data-bs-toggle="dropdown"
                  //   aria-expanded="false"
                  onClick={() => handleItemClick("")}
                >
                  Institute
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Nav;
