import { ImageUtils } from "../../Utils/imageUtils";
export const Marquee_first_arr = [
  {
    image_url: ImageUtils.HTML_5,
    name: "HTML5",
  },
  {
    image_url: ImageUtils.CSS_3,
    // image_url: "https://res.cloudinary.com/dpirjd4tk/image/upload/f_auto,q_auto/v1/CT-site-images/Programming_logos/css_3.png",
    name: "CSS3",
  },
  {
    image_url: ImageUtils.Php,
    name: "PHP",
  },
  {
    image_url: ImageUtils.C_icon,
    name: "C",
  },
  {
    image_url: ImageUtils.C_Sharp,
    name: "C-Sharp",
  },
  {
    image_url: ImageUtils.Jquery,
    name: "Jquery",
  },
  {
    image_url: ImageUtils.Javascript,
    name: "JavaScript",
  },
  {
    image_url: ImageUtils.Reactjs,
    name: "React",
  },
  {
    image_url: ImageUtils.Angular,
    // image_url: "https://res.cloudinary.com/dpirjd4tk/image/upload/v1702131324/CT-site-images/Programming_logos/Angular.png",
    name: "Angular",
  },
 
  {
    image_url: ImageUtils.Vue_Js,
    name: "Vue.js",
  },
 
  {
    image_url: ImageUtils.Expressjs,
    name: "Expressjs",
  },
  {
    image_url: ImageUtils.Flutter,
    name: "Flutter",
  },
  {
    image_url: ImageUtils.Reactjs,
    name: "React Native",
  },
  {
    image_url: ImageUtils.Swift,
    name: "Swift",
  },
  // {
  //   image_url: ImageUtils.MERN,
  //   name: "MERN Stack",
  // },
];
export const Marquee_second_arr = [
  {
    image_url: ImageUtils.Mongodb,
    name: "MongoDB",
  },
  {
    image_url: ImageUtils.My_Sql,
    name: "MySQL",
  },
  {
    image_url: ImageUtils.SQL,
    name: "MS-SQL",
  },
  {
    image_url: ImageUtils.Postgre_sql,
    name: "Postgre-SQL",
  },
  {
    image_url: ImageUtils.FireBase,
    name: "Firebase",
  },
  {
    image_url: ImageUtils.Docker,
    name: "Docker",
  },
  {
    image_url: ImageUtils.Selenium,
    name: "Selenium",
  },
  {
    image_url: ImageUtils.Aws,
    name: "AWS",
  },
  {
    image_url: ImageUtils.Azure,
    name: "Azure",
  },
  {
    image_url: ImageUtils.Java,
    name: "Java",
  },
  {
    image_url: ImageUtils.NodeJs,
    name: "NodeJs",
  },
  {
    image_url: ImageUtils.Python,
    name: "Python",
  },
  {
    image_url: ImageUtils.Django,
    name: "Django",
  },
  {
    image_url: ImageUtils.Appium,
    name: "Appium",
  },
  {
    image_url: ImageUtils.Git,
    name: "Git",
  },
  {
    image_url: ImageUtils.Maven,
    name: "Maven",
  },
  {
    image_url: ImageUtils.Jenkins,
    name: "Jenkins",
  },
];
