//** components imported */
import Home from "../../Pages/Home";
import Career from "../../Pages/Career/Career";
import CareerForm from "../../Pages/Career/Careerform";

// import Login from "../../pages/login-register/log-in";
const protectedRoutes = [
    {
        path: "",
        component: "",
        exact: true,
    }
];
const publicRoutes = [
    {
        path: "/",
        component: <Home/>,
        exact: true,
    },
    {
        path: "/career",
        component: <Career/>,
        exact: true,
    },
    {
        path: "/career_form",
        component: <CareerForm/>,
        exact: true,
    },
    // {
    //     path: "login",
    //     component: <Login/>,
    //     exact: true,
    // }
];

export { protectedRoutes, publicRoutes };