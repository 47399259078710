import React, { useState, useEffect } from "react";
import "../../Core/Styles/Style.css";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ImageUtils } from "../../Utils/imageUtils";
import { sendContactForm } from "../../Services";
import { FollowUsData } from "../../Utils/follow-us.utils";
function Footer() {
  // contact form and scroll smooth state managed
  const [activeSection, setActiveSection] = useState(null);
  const [constact, setContact] = useState({
    mobile_number: "",
    full_name: "",
    email: "",
    message: "",
  });
  const [initialVal, setInitialVal] = useState({
    mobile_number_err: false,
    full_name_err: false,
    email_err: false,
    message_err: false,
  });

  const handleItemClick = (sectionId) => {
    setActiveSection(sectionId);
  };

  useEffect(() => {
    if (activeSection) {
      const element = document.getElementById(activeSection);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [activeSection]);
  //** handle input change enter function */
  const handleChange = (event) => {
    setContact((preProps) => ({
      ...preProps,
      [event.target.name]: event.target.value,
    }));
  };
  const isValidationCheck = () => {
    let isValid = true;
    if (constact.mobile_number == "" || constact.mobile_number === undefined) {
      isValid = false;
      setInitialVal((preProps) => ({
        ...preProps,
        mobile_number_err: "Mobile number is required",
      }));
    } else {
      setInitialVal((preProps) => ({ ...preProps, mobile_number_err: "" }));
    }
    if (constact.full_name == "" || constact.full_name === undefined) {
      isValid = false;
      setInitialVal((preProps) => ({
        ...preProps,
        full_name_err: "Name is required",
      }));
    } else {
      setInitialVal((preProps) => ({ ...preProps, full_name_err: "" }));
    }
    if (constact.email == "" || constact.email === undefined) {
      isValid = false;
      setInitialVal((preProps) => ({
        ...preProps,
        email_err: "Email is required",
      }));
    } else {
      setInitialVal((preProps) => ({ ...preProps, email_err: "" }));
    }
    if (constact.message == "" || constact.message === undefined) {
      isValid = false;
      setInitialVal((preProps) => ({
        ...preProps,
        message_err: "Message is required",
      }));
    } else {
      setInitialVal((preProps) => ({ ...preProps, message_err: "" }));
    }
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { full_name, email, mobile_number, message } = constact;
    const payload = {
      name: full_name,
      email: email,
      mobileNumber: mobile_number,
      message: message,
    };
    var isValid = isValidationCheck();
    if (isValid) {
      const response = await sendContactForm(payload);
      if (response.code == 200) {
        // console.log({message: response.message});
        await toast.success(response.message, {
          // position: toast.POSITION.BOTTOM_RIGHT,
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setContact({
          email: "",
          full_name: "",
          mobile_number: "",
          message: "",
        });
      } else {
        // console.log({error: response.error, message: response.message});
        await toast.error(response.message, {
          // position: toast.POSITION.BOTTOM_RIGHT,
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      // console.log("response===========", response);
    }
  };
  return (
    <footer className="footer-section">
      <ToastContainer transition={Slide} />
      <div className="container-fluid">


        <div className="row row-cols-1 row-cols-lg-5 g-4 py-3 px-2 ">
          <div className="col mb-50">
            <div className="footer-widget">
              <div className="footer-logo top-50">
                <a className="d-flex align-items-center"
                  // href=""
                  onClick={() => handleItemClick("home")}
                >
                  {" "}
                  <img src={ImageUtils.Logo} />
                  <p className="logo-text-sizing">Kamrinaa Technologies</p>
                </a>
              </div>
              {/* <div className="footer-text">
                    <p>Lorem ipsum dolor sit amet, consec tetur adipisicing elit, sed do eiusmod tempor incididuntut consec tetur adipisicing
                      elit,Lorem ipsum dolor sit amet.</p>
                  </div> */}

            </div>
          </div>
          <div className="col mb-30">
            <div className="footer-widget">
              <div className="footer-widget-heading">
                <h3>Useful Links</h3>
              </div>
              <ul>
                <li>
                  <a href="" onClick={() => handleItemClick("home")}>
                    Home
                  </a>
                </li>
                <li>
                  <a href="" onClick={() => handleItemClick("Technical_Services")}
                  >
                    About us
                  </a>
                </li>
                <li>
                  <a href="" onClick={() => handleItemClick("Services")}>
                    Our Services
                  </a>
                </li>
                <li>
                  <a href="" data-bs-toggle="modal" data-bs-target="#pricingModal">
                    Pricing
                  </a>
                </li>
                <li>
                  <a href="" onClick={() => handleItemClick("our_team")}>
                    Expert Team
                  </a>
                </li>

              </ul>
            </div>
          </div>
          <div className="col  mb-30">
            <div className="single-cta">
              <i className="fas fa-map-marker-alt"></i>
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Find us</h3>
                </div>

                <ul>
                  <li>
                    Kamrinaa Technologies
                  </li>

                  <li>
                    TVS Nagar
                  </li>

                  <li>
                    Nearby Mosque
                  </li>

                  <li>
                    Madurai
                  </li>

                  <li>
                    Tamil Nadu.
                  </li>

                </ul>
              </div>
            </div>
          </div>
          <div className="col mb-30">


            <div className="">
              <div className="footer-widget-heading">
                <h3>Call us</h3>
              </div>

              <span>+91 6384021940</span>
            </div>



            <div className="mt-5">
              <div className="footer-widget-heading">
                <h3>Mail us</h3>
              </div>

              <span>sales@kamrinaatechnologies.com</span>

            </div>
          </div>
          <div className="col mb-30">
            <div className="footer-social-icon">
              <div className="footer-widget-heading">
                <h3>Follow us</h3>
              </div>

              {FollowUsData &&
                FollowUsData.length &&
                FollowUsData.map((media) => (
                  <a href={media.link} target="_blank">
                    {/* <i className="fab fa-facebook-f facebook-bg"></i> */}
                    <img
                      src={media.image_url}
                      alt={media.label}
                      className=""
                      width={30}
                      height={30}
                    />
                  </a>
                ))}
            </div>
          </div>
        </div>



      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 text-center text-lg-left">
              <div className="copyright-text">
                <p>
                  Kamrinaa Technologies Software Solutions &copy; 2024, All Right Reserved{" "}
                  <a href="https://KamrinaaTechnologies.com/" target="_blank">
                    KT-Team
                  </a>
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 d-block d-lg-block text-right">
              <div className="footer-menu">
                <ul>
                  <li>
                    <a href="https://kamrinaatechnologies.com/termsandconditions.html" target="_blank">Terms and conditions </a>
                  </li>
                  <li>
                    <a href="https://kamrinaatechnologies.com/privacypolicy.html" target="_blank">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="https://kamrinaatechnologies.com/refundcancellationpolicy.html" target="_blank">
                      Refund and Cancellation
                    </a>
                  </li>
                  <li>
                    <a href="https://kamrinaatechnologies.com/shippingpolicy.html" target="_blank">
                      Shipping Policy
                    </a>
                  </li>
                  {/* <li>
                    <a href="#">Policy</a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // <!-- Modal-- > */}
      <div class="modal fade " id="pricingModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="pricingModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg ">
          <div class="modal-content ">
            <div class="modal-header border-0 justify-content-end pb-0">

              <button type="button" class="btn-close bg-white ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body pt-0">
              <div className="text-center">
                <p className="third-section-title-text-css-1 text-center">
                  PRICING
                </p>
              </div>
              <div className="container">
                <div className="row row-cols-lg-2 row-cols-1 g-3 justify-content-between align-items-center">
                  <div className="col ">
                    <div className="inner-card-design-css-2 card h-100">
                      <div className="text-center inner-card-design-css pt-3 pb-5">
                        <img
                          className="footer-card-image-css-styling"
                          // src={Web}
                          src={ImageUtils.Web}
                        />
                        <div className="footer-heading-height-css d-flex flex-column align-items-center">
                          <p className="footer-card-section-heading-1  mt-2 mb-0">
                            Web <br />development
                          </p>

                        </div>
                      </div>
                      <div className="text-center pt-3 pb-2">
                        <p className="footer-card-section-heading-2  ">
                          ₹ 10,000
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col ">
                    <div className="inner-card-design-css-2 card h-100">
                      <div className="text-center inner-card-design-css pt-3 pb-5">
                        <img
                          className="footer-card-image-css-styling"
                          // src={Web}
                          src="https://www.orientsoftware.com/Themes/OrientSoftwareTheme/Content/Images/technologies/android/osd-android-1.png"
                        />
                        <div className="footer-heading-height-css d-flex flex-column">
                          <p className="footer-card-section-heading-1  mt-2 mb-0">
                            Mobile app development <br />[ Android]
                          </p>

                        </div>
                      </div>
                      <div className="text-center pt-3 pb-2 ">
                        <p className="footer-card-section-heading-2  ">
                          ₹ 25,000
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </footer>






  );
}

export default Footer;
