import { ImageUtils } from "./imageUtils";
export const FollowUsData = [
    {
        label: "twitter",
        image_url: ImageUtils.Twitter,
        link: "https://twitter.com/KamrinaaTechnologies",
    },
    {
        label: "you-tube",
        image_url: ImageUtils.Youtube,
        link: "https://www.youtube.com/@KamrinaaTechnologies",
    },
    {
        label: "facebook",
        image_url: ImageUtils.Facebook,
        link: "https://www.facebook.com/profile.php?id=61554214217626",
    },
    {
        label: "Linked-in",
        image_url: ImageUtils.Linked_in,
        link: "https://www.linkedin.com/in/kamrinaa-technologies-2203992a5/",
    },

];
// export default { FollowUsData };