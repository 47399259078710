import React from "react";
import "../../Core/Styles/Style.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import $ from "jquery";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import Marquee from "react-fast-marquee";
// import jQuery from "jquery";
import { LiaChessQueenSolid } from "react-icons/lia";
// imprt image-utils
import { ImageUtils } from "../../Utils/imageUtils";
import { Marquee_first_arr, Marquee_second_arr } from "./data";
import Layout from "../../Components/layout";
import { Image } from 'cloudinary-react';
function Home() {





  return (
    <div>
      {/* =======================================FIRST-SECTION================================================= */}
      <Layout>
        <section id="homebannerSection" className="">
          <div className="container-fluid col-12  px-0" id="home">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              fade={{ crossFade: true }}
              virtualTranslate={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              loop={true}
              effect={"fade"}
              modules={[Autoplay, EffectFade]}
              className="mySwiper "
            >
              <SwiperSlide className="">
                <div className=" swiper-bg-image-styling-1    text-center ">
                  <div className="banner-position-css row justify-content-center align-items-center min-vh-lg-100">
                    <div className="col-lg-9">
                      <p className="banner-heading-text-css-1">
                        Innovative IT Solutions for Modern Businesses
                      </p>
                      {/* <p className="banner-heading-text-css-2">Power Human-Machine Collaboration with us</p> */}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-bg-image-styling-2 text-center">
                  <div className="banner-position-css row justify-content-center align-items-center min-vh-lg-100">
                    <div className="col-lg-9">
                      <p className="banner-heading-text-css-1">
                        Efficiently Managing IT Projects for Business Success
                      </p>
                      {/* <p className="banner-heading-text-css-2">Power Human-Machine Collaboration with us</p> */}
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="swiper-bg-image-styling-3   text-center">
                  <div className="banner-position-css row justify-content-center align-items-center min-vh-lg-100">
                    <div className="col-lg-9">
                      <p className="banner-heading-text-css-1">
                        Exploring the impact of Artificial Intelligence in
                        software development
                      </p>
                      {/* <p className="banner-heading-text-css-2">Power Human-Machine Collaboration with us</p> */}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </section>

        {/* =======================================SECOND-SECTION================================================= */}

        <section className="glass-section-css-styling">
          <div className="container-fluid glass-panel">
            <p className="second-section-heading-1 text-center">
              Kamrinaa Technologies develop your innovation and dream
            </p>
            <div className="row justify-content-center align-items-center text-lg-center text-justify">
              <div className="col-lg-7">
                <p className="second-section-heading-2 ">
                  Our team develops a user – friendly and perceptible appealing
                  websites and mobile applications that clearly communicates our
                  client’s software benefits.We are using the Agile Methology it
                  is a rigid process for project management, So the delivery of
                  the product will be on time, Our main goal is to satisfy the
                  client and provide best quality products to our clients.
                </p>
                {/* <p className="second-section-heading-2 text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p> */}
                <button className="btn button-container">
                  <p className="glass-button fancy-title">
                    <span>Get a Demo </span>
                  </p>
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* =======================================THIRD-SECTION================================================= */}

        {/* <section id="technicalservices"> */}
        <section id="Technical_Services" className="">

          <div className="container py-lg-4">
            <p className="third-section-title-text-css-1 text-center">
              Technical Services{" "}
            </p>

            <div className="row flex-lg-row flex-lg-row-reverse row-cols-lg-2 row-cols-1 justify-content-evenly align-items-center g-4 mb-5">
              <div className="col-lg-4 ">
                <img
                  className="card-image-css-styling"
                  // src={Web}
                  src={ImageUtils.Web}
                />
              </div>
              <div className="col-lg-6 text-justify">
                <p className="third-section-heading-1 mb-2">
                  Web Design And Development{" "}
                </p>
                <p>
                  When it comes to building websites, applications, or software,
                  development is the backbone of our company. It is the art of
                  transforming ideas and concepts into practical, functional
                  solutions.{" "}
                </p>
                <p>
                  Front-end development focuses on the visual and interactive
                  aspects of a digital product.Kamrinaa Technologieshaving team of experts
                  in this field use HTML, CSS, and JavaScript to bring designs
                  to life.
                </p>
                <p>
                  Back-end development powers behind-the-scenes magic.
                  Kamrinaa Technologiesinvolves building and maintaining server-side
                  applications, databases, and APIs. We have a holistic
                  understanding of the entire development process and can handle
                  both client-side and server-side tasks.
                </p>
              </div>
            </div>
            <div className="row  row-cols-lg-2 row-cols-1 justify-content-evenly align-items-center g-4 mb-5">
              <div className="col-lg-4 ">
                <img
                  className="card-image-css-styling"
                  // src={Mobile}
                  src={ImageUtils.Mobile}
                />
              </div>
              <div className="col-lg-6 text-justify">
                <p className="third-section-heading-1 mb-2">
                  Mobile APP Design And Development
                </p>
                <p>
                  Revolutionising the Palm of Your Hand and this world driven by
                  smartphones. Kamrinaa Technologiesdesigns and develops mobile apps for
                  our clients and to make their businesses more profitable .
                  From gaming to productivity tools, mobile apps have
                  transformed the way we interact with technology.{" "}
                </p>
                <p>
                  Native app development involves creating applications for
                  specific platforms, such as iOS or Android, using dedicated
                  programming languages and development frameworks.
                </p>
                <p>
                  Hybrid app development efficiency in Cross-Platform
                  Development which allows for the creation of apps that work on
                  multiple platforms using a single codebase. It offers
                  cost-effectiveness, faster development, and easier
                  maintenance.{" "}
                </p>
                <p>
                  App Store Optimization (ASO) is the process of optimizing
                  mobile apps to increase visibility and maximize downloads. It
                  involves strategies like keyword optimization, appealing
                  visual assets, and positive user reviews.
                </p>
              </div>
            </div>

            <div className="row flex-lg-row flex-lg-row-reverse row-cols-lg-2 row-cols-1 justify-content-evenly align-items-center g-4 mb-5">
              <div className="col-lg-4 text-lg-start">
                <img
                  className="card-image-css-styling"
                  // src={maintance}
                  src={ImageUtils.Maintance}
                />
              </div>
              <div className="col-lg-6  text-justify">
                <p className="third-section-heading-1 mb-2">
                  Website Redesign Maintenance
                </p>
                <p>
                  Kamrinaa Technologies technicalized in the art of remodeling and
                  redeveloping of your existing websites,deluging them with
                  Up-to-date advanced techological fresh ideas, so our client
                  website will changen to captivating online asset.
                </p>
                <p>
                  Make sure to update on our annual maintenance service,where
                  Kamrinaa Technologies proving with reasonable cost, so website runs
                  smoothly, with security checks, regular updates, and
                  performance optimizations.
                </p>
              </div>
            </div>
            <div className="row  row-cols-lg-2 row-cols-1 justify-content-evenly align-items-center g-4 mb-5">
              <div className="col-lg-4 ">
                <img
                  className="card-image-css-styling"
                  // src={Digital}
                  src={ImageUtils.Digital}
                />
              </div>
              <div className="col-lg-6 text-justify">
                <p className="third-section-heading-1 mb-2">
                  Digital Marketing
                </p>
                <p>
                  Kamrinaa Technologies plays a vital role in Search engine optimization
                  which provide a driving organic traffic to websites and
                  improving online visibility, which providing a genuine service
                  for On-Page SEO which will Optimize for Search Engines and
                  Off- Page SEO which will Build authority and Influence.
                </p>
                <p>
                  Technical SEO focuses on optimizing our clients website
                  structure and performance to ensure search engines can crawl,
                  index, and understand the content easily. It involves tasks
                  such as optimizing site speed, fixing broken links, and
                  improving mobile-friendliness.
                </p>
                <p>
                  Social media optimization is not just about posting content in
                  all social platforms and forums, it is about creating engaging
                  experiences that drive traffic to our client website and
                  convert visitors into customers.
                </p>
              </div>
            </div>
            <div className="row flex-lg-row flex-lg-row-reverse row-cols-lg-2 row-cols-1 justify-content-evenly align-items-center g-4 ">
              <div className="col-lg-4 text-lg-start">
                <img
                  className="card-image-css-styling"
                  // src={Dedicate}
                  src={ImageUtils.Dedicate}
                />
              </div>
              <div className="col-lg-6 text-justify">
                <p className="third-section-heading-1 mb-2">
                  Providing a Dedicated employees
                </p>
                <p>
                  We Kamrinaa Technologies providing a goal -oriented and proactive
                  employees for our client’s.For the specific designation: Web
                  Developers,App Developers,Web Designer, UI UX
                  Designer,Software Testers , SEO Analysts where our employee
                  will produce high- quality results to our clients.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* =======================================FOURTH-SECTION================================================= */}

        <section className="" id="Technologies">
          <div className="container-fluid py-lg-5">
            <p className="third-section-title-text-css-1 text-center">
              Popular Technologies Habituated by Kamrinaa Technologies
            </p>
            <div className="marquee-full-bg py-5">
              <Marquee className="" direction="left" loop="infinite" speed="70">
                {Marquee_first_arr &&
                  Marquee_first_arr.length &&
                  Marquee_first_arr.map((data_1) => (
                    <div className="marquee-card-styling ">
                      <img
                        className="tech-icon-css-styling"
                        src={data_1.image_url}
                      />
                      {/* <Image cloudName="dpirjd4tk" publicId="css_3.png" /> */}

                      <p className="marquee-section-heading-1 mb-0">
                        {data_1.name}
                      </p>
                    </div>
                  ))}

              </Marquee>
              <Marquee
                className="mt-4"
                direction="right"
                loop="infinite"
                speed="70"
              >
                {Marquee_second_arr &&
                  Marquee_second_arr.length &&
                  Marquee_second_arr.map((data_2) => (
                    <div className="marquee-card-styling">
                      <img
                        className="tech-icon-css-styling"
                        src={data_2.image_url}
                      />
                      {/* <Image cloudName="dpirjd4tk" publicId="css_3.png" /> */}
                      <p className="marquee-section-heading-1 mb-0">
                        {data_2.name}
                      </p>
                    </div>
                  ))}

              </Marquee>
            </div>
          </div>
        </section>

        {/* =======================================FIFTH-SECTION================================================= */}

        <section className="pt-5 " id="Services">
          <div className="container-fluid px-0 overflow-hidden" id="Services">
            <p className="third-section-title-text-css-1 text-center">
              Services
            </p>
            <svg width="0" height="0">
              <defs>
                <clipPath id="myCurve" clipPathUnits="objectBoundingBox">
                  <path
                    d="M 0,1
									L 0,0
									L 1,0
									L 1,1
									C .65 .8, .35 .8, 0 1
									Z"
                  />
                </clipPath>
              </defs>
            </svg>
            <svg width="0" height="0">
              <defs>
                <clipPath id="myCurvetwo" clipPathUnits="objectBoundingBox">
                  <path
                    d="M 0,0
           L 0,1
           L 1,1
           L 1,0
           C .65 .2, .35 .2, 0 0
           Z"
                  />
                </clipPath>
              </defs>
            </svg>

            <div className="clippath-css-styling-2 px-0 mx-0">
              <div className="clippath-css-styling row row-cols-lg-5 row-cols-1 justify-content-evenly align-items-center text-center">
                <div className="col pe-lg-0">
                  <div className="port-1">
                    <div className="portfolioDisc">
                      <p className="curve-card-heading-1">Construction</p>
                      <p className="curve-card-para-1 px-3">
                        {" "}
                        Elevate your ventures with Kamrinaa Technologies's cutting-edge
                        services, meticulously crafted for the Construction,
                        Builders, and Promoters domains. Our solutions
                        seamlessly integrate technology to enhance efficiency
                        and drive success in projects.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col pe-lg-0">
                  <div className="port-2">
                    <div className="portfolioDisc">
                      <p className="curve-card-heading-1">Healthcare </p>
                      <p className="curve-card-para-1 px-3">
                        Kamrinaa Technologies transforms healthcare with cutting-edge
                        solutions, prioritizing innovation for streamlined
                        operations and enhanced patient care. Elevate your
                        healthcare experience with our state-of-the-art
                        services.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col pe-lg-0">
                  <div className="port-3">
                    <div className="portfolioDisc">
                      <p className="curve-card-heading-1">Booking Tickets </p>
                      <p className="curve-card-para-1 px-3">
                        {" "}
                        Kamrinaa Technologies offers a convenient ticket booking service,
                        ensuring hassle-free access to your favorite events,
                        shows, or travel plans. Say goodbye to complications and
                        hello to a smooth ticketing experience with Kamrinaa Technologies.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col pe-lg-0">
                  <div className="port-4">
                    <div className="portfolioDisc">
                      <p className="curve-card-heading-1">Hotel Industry</p>
                      <p className="curve-card-para-1 px-3">
                        We redefine hospitality tech, ensuring seamless
                        operations and unparalleled guest experiences. Let
                        innovation meet excellence in the heart of your hotel
                        business.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col pe-lg-0">
                  <div className="port-5">
                    <div className="portfolioDisc">
                      <p className="curve-card-heading-1">
                        GPS Tracking System{" "}
                      </p>
                      <p className="curve-card-para-1 px-3">
                        {" "}
                        Kamrinaa Technologies offers cutting-edge GPS Tracking System
                        services, providing real-time and accurate location
                        information. Trust us to enhance your tracking
                        capabilities for a wide range of applications
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* =======================================SIXTH-SECTION================================================= */}

        <section id="tabsSection">
          <div className="container" >
            <div className="row justify-content-center text-center aliogn-items-center">
              <p className="third-section-title-text-css-1 text-center">
                Our Website Design Process{" "}
              </p>
              {/* <p className="sixth-section-text-css-2 w-lg-50">How to create a website tailored to your unique business goals?
              Take a look at our design process that helps us deliver customized and goal-oriented digital solutions.</p> */}
            </div>
          </div>
          <div className="container-fluid col-12 py-5 overflow-hidden" id="process">
            <div className="over-scroll-1">
              <div className="over-scroll-2">
                <ul
                  className="line-height-tabs row row-cols-6 justify-content-between align-items-center text-center  nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="col nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-first-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-first"
                      type="button"
                      role="tab"
                      aria-controls="pills-first"
                      aria-selected="false"
                    >
                      <p className="tabs-heading-css">
                        Web <br /> Strategy
                      </p>
                      <div className="tabs-icon-div-styling-css">
                        <LiaChessQueenSolid className="tabs-icon-styling-css" />
                      </div>
                    </button>
                  </li>
                  <li className="col nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-second-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-second"
                      type="button"
                      role="tab"
                      aria-controls="pills-second"
                      aria-selected="false"
                    >
                      <p className="tabs-heading-css">
                        Planning & Information Architecture
                      </p>
                      <div className="tabs-icon-div-styling-css">
                        <LiaChessQueenSolid className="tabs-icon-styling-css" />
                      </div>
                    </button>
                  </li>
                  <li className="col nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-third-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-third"
                      type="button"
                      role="tab"
                      aria-controls="pills-third"
                      aria-selected="false"
                    >
                      <p className="tabs-heading-css">Creative Design</p>
                      <div className="tabs-icon-div-styling-css">
                        <LiaChessQueenSolid className="tabs-icon-styling-css" />
                      </div>
                    </button>
                  </li>
                  <li className="col nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-fourth-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-fourth"
                      type="button"
                      role="tab"
                      aria-controls="pills-fourth"
                      aria-selected="false"
                    >
                      <p className="tabs-heading-css">Responsive Development</p>
                      <div className="tabs-icon-div-styling-css">
                        <LiaChessQueenSolid className="tabs-icon-styling-css" />
                      </div>
                    </button>
                  </li>
                  <li className="col nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-fifth-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-fifth"
                      type="button"
                      role="tab"
                      aria-controls="pills-fifth"
                      aria-selected="false"
                    >
                      <p className="tabs-heading-css">Quality Assurance (QA)</p>
                      <div className="tabs-icon-div-styling-css">
                        <LiaChessQueenSolid className="tabs-icon-styling-css" />
                      </div>
                    </button>
                  </li>
                  <li className="col nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-sixth-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-sixth"
                      type="button"
                      role="tab"
                      aria-controls="pills-sixth"
                      aria-selected="false"
                    >
                      <p className="tabs-heading-css">Launch & Optimization</p>
                      <div className="tabs-icon-div-styling-css">
                        <LiaChessQueenSolid className="tabs-icon-styling-css" />
                      </div>
                    </button>
                  </li>
                </ul>

                <div className="tab-content px-5" id=" pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-first"
                    role="tabpanel"
                    aria-labelledby="pills-first-tab"
                    tabindex="0"
                  >
                    <div className="row row-cols-1 row-cols-lg-2 pt-5">
                      <div className="col-lg-6">
                        <p className="tabs-inner-heading-css">Web Strategy</p>
                        <p className="tabs-inner-para-css">
                          Prior to design, we thoroughly research your industry
                          and competitors, shaping a custom digital strategy.
                          This involves defining brand positioning, collecting
                          audience data, and setting measurable KPIs for optimal
                          performance
                        </p>
                      </div>
                      {/* <div className="col-lg-6 text-center">
                  <img className="tabs-content-image-css" src={Mobile} />
                </div> */}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-second"
                    role="tabpanel"
                    aria-labelledby="pills-second-tab"
                    tabindex="0"
                  >
                    <div className="row row-cols-1 row-cols-lg-2 pt-5">
                      <div className="col-lg-6">
                        <p className="tabs-inner-heading-css">
                          Planning & Information Architecture
                        </p>
                        <p className="tabs-inner-para-css">
                          {" "}
                          We then craft unique landing pages, plan your site's
                          structure and navigation, and develop a strategy for
                          URL structure, redirects, and SEO. Our goal is to
                          preserve any existing SEO value from your current
                          site, if applicable.
                        </p>
                      </div>
                      {/* <div className="col-lg-6 text-center">
                  <img className="tabs-content-image-css" src={maintance} />
                </div> */}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-third"
                    role="tabpanel"
                    aria-labelledby="pills-third-tab"
                    tabindex="0"
                  >
                    <div className="row row-cols-1 row-cols-lg-2 pt-5">
                      <div className="col-lg-6">
                        <p className="tabs-inner-heading-css">
                          Creative Design
                        </p>
                        <p className="tabs-inner-para-css">
                          Watch your website come alive with our award-winning
                          designers! We infuse vibrant colors, custom elements,
                          and tailor the user journey to match your brand's
                          personality and target audience.
                        </p>
                      </div>
                      {/* <div className="col-lg-6 text-center">
                  <img className="tabs-content-image-css" src={Digital} />
                </div> */}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-fourth"
                    role="tabpanel"
                    aria-labelledby="pills-fourth-tab"
                    tabindex="0"
                  >
                    <div className="row row-cols-1 row-cols-lg-2 pt-5">
                      <div className="col-lg-6">
                        <p className="tabs-inner-heading-css">
                          Responsive Development
                        </p>
                        <p className="tabs-inner-para-css">
                          Our skilled development team crafts a secure and
                          scalable solution tailored to your brand in the chosen
                          platform, from B2B to eCommerce. We prioritize clean
                          code and deliver an optimized website.
                        </p>
                      </div>
                      {/* <div className="col-lg-6 text-center">
                  <img className="tabs-content-image-css" src={Dedicate} />
                </div> */}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-fifth"
                    role="tabpanel"
                    aria-labelledby="pills-fifth-tab"
                    tabindex="0"
                  >
                    <div className="row row-cols-1 row-cols-lg-2 pt-5">
                      <div className="col-lg-6">
                        <p className="tabs-inner-heading-css">
                          Quality Assurance (QA)
                        </p>
                        <p className="tabs-inner-para-css">
                          Before going live, we rigorously ensure quality for
                          your custom solution. Our thorough testing covers site
                          speed, functionality, and other crucial elements,
                          leaving no detail unchecked to ensure a flawless
                          launch.
                        </p>
                      </div>
                      {/* <div className="col-lg-6 text-center">
                  <img className="tabs-content-image-css" src={Web} />
                </div> */}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-sixth"
                    role="tabpanel"
                    aria-labelledby="pills-sixth-tab"
                    tabindex="0"
                  >
                    <div className="row row-cols-1 row-cols-lg-2 pt-5">
                      <div className="col-lg-6">
                        <p className="tabs-inner-heading-css">
                          Launch & Optimization
                        </p>
                        <p className="tabs-inner-para-css">
                          After our quality assurance checks are done, it's
                          launch time! We'll handle server setup, DNS
                          configuration, and ensure all updates are wrapped up
                          for a smooth launch.
                        </p>
                      </div>
                      {/* <div className="col-lg-6 text-center">
                  <img className="tabs-content-image-css" src={Web} />
                </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* =======================================OUR-TEAM-SECTION================================================= */}

        <section id="our_team">
          <div className="container pb-5">
            <p className="third-section-title-text-css-1 text-center pb-4">
              Our Team
            </p>

            <div id="ourteam_testing" className="container">
              <div className="row row-cols-lg-4 row-cols-1 g-4">
                <div className="col ">
                  <div className="inner-card-design-css-2 card h-100">
                    <div className="text-center inner-card-design-css pt-3 pb-5">
                      <img
                        className="our-team-card-image-css-styling"
                        // src={Web}
                        src={ImageUtils.PM}
                      />

                    </div>
                    <div className="text-center pt-3 pb-2">
                      <p className="our-team-section-heading-2  ">
                        Project Manager
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col ">
                  <div className="inner-card-design-css-2 card h-100">
                    <div className="text-center inner-card-design-css pt-3 pb-5">
                      <img
                        className="our-team-card-image-css-styling"
                        // src={Web}
                        src={ImageUtils.Dev_TL}
                      />

                    </div>
                    <div className="text-center pt-3 pb-2">
                      <p className="our-team-section-heading-2  ">
                        Developement Team Lead
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col ">
                  <div className="inner-card-design-css-2 card h-100">
                    <div className="text-center inner-card-design-css pt-3 pb-5">
                      <img
                        className="our-team-card-image-css-styling"
                        // src={Web}
                        src={ImageUtils.Test_TL}
                      />

                    </div>
                    <div className="text-center pt-3 pb-2">
                      <p className="our-team-section-heading-2  ">
                        Testing Team Lead
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col ">
                  <div className="inner-card-design-css-2 card h-100">
                    <div className="text-center inner-card-design-css pt-3 pb-5">
                      <img
                        className="our-team-card-image-css-styling"
                        // src={Web}
                        src={ImageUtils.Market_TL}
                      />

                    </div>
                    <div className="text-center pt-3 pb-2">
                      <p className="our-team-section-heading-2  ">
                        Marketing  Team Lead
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </section>

        {/* =======================================FOOTER-SECTION================================================= */}
      </Layout>
    </div>
  );
}

export default Home;
