// import Config from "../Core/configs";
export const ImageUtils = {
  Logo: require("../Assests/Images/logo/KT.jpg"),
  Nav_Logo: require("../Assests/Images/logo/KT.jpg"),
  Appium: require("../Assests/Images/Technologies/Appium.png"),
  Docker: require("../Assests/Images/Technologies/new/docker.png"),
  Expressjs: require("../Assests/Images/Technologies/expressjs_logo.png"),
  NodeJs: require("../Assests/Images/Technologies/new/nodejs.png"),
  MERN: require("../Assests/Images/Technologies/new/mern.png"),
  Swift: require("../Assests/Images/Technologies/new/swift.png"),
  Django: require("../Assests/Images/Technologies/new/django.png"),
  Maven: require("../Assests/Images/Technologies/new/maven.png"),
  CSS_3: require("../Assests/Images/Technologies/css3-1.png"),
  C_icon: require("../Assests/Images/Technologies/new/c.png"),
  C_Sharp: require("../Assests/Images/Technologies/new/csharp.png"),
  // CSS_3: require(`${Config.CLOUDINARY_URL}Programming_logos/css_3.png`),

  FireBase: require("../Assests/Images/Technologies/new/firebase.png"),
  HTML_5: require("../Assests/Images/Technologies/HTML5_Badge.svg.png"),
  Git: require("../Assests/Images/Technologies/git.jpg"),
  Flutter: require("../Assests/Images/Technologies/new/flutter.png"),
  Reactjs: require("../Assests/Images/Technologies/new/react.png"),
  // React_Native: require("../Assests/Images/Technologies/Reactnative.png"),
  Angular: require("../Assests/Images/Technologies/angularjs.png"),
  // Angular: require(`${Config.CLOUDINARY_URL}Programming_logos/Angular.png`),

  Aws: require("../Assests/Images/Technologies/new/aws.png"),
  Vue_Js: require("../Assests/Images/Technologies/new/vuejs.png"),
  Selenium: require("../Assests/Images/Technologies/selenium.png"),
  Python: require("../Assests/Images/Technologies/new/python.png"),
  Php: require("../Assests/Images/Technologies/new/php.png"),
  My_Sql: require("../Assests/Images/Technologies/new/mysql.png"),
  SQL: require("../Assests/Images/Technologies/new/sql-server.png"),
  Postgre_sql: require("../Assests/Images/Technologies/new/postgresql.png"),
  Mongodb: require("../Assests/Images/Technologies/mongodb.png"),
  Azure: require("../Assests/Images/Technologies/new/azure.png"),
  Jquery: require("../Assests/Images/Technologies/new/jquery.png"),
  Javascript: require("../Assests/Images/Technologies/new/javascript.png"),
  Jenkins: require("../Assests/Images/Technologies/new/jenkins.png"),
  Java: require("../Assests/Images/Technologies/java_1.png"),
  // Java: require(`${Config.CLOUDINARY_URL}Programming_logos/java.png`),

  Dedicate: require("../Assests/Images/Technical services/Dedicate dev.jpg"),
  Digital: require("../Assests/Images/Technical services/Digital marketing.jpg"),
  Mobile: require("../Assests/Images/Technical services/Mobile APP Design And Development.jpg"),
  Web: require("../Assests/Images/Technical services/Web Design And  Development.jpg"),
  Maintance: require("../Assests/Images/Technical services/web maintance (2).jpg"),
  //** team members images */
  PM: require("../Assests/Images/Team/person-4.png"),
  Dev_TL: require("../Assests/Images/Team/person-2.png"),
  Test_TL: require("../Assests/Images/Team/person-3.png"),
  Market_TL: require("../Assests/Images/Team/person-1.png"),

  //** follow-us images */
  // Youtube: require("../Assests/Images/Social-media/youtube.png"),
  // Twitter: require("../Assests/Images/Social-media/x_twitter.png"),
  // Linked_in: require("../Assests/Images/Social-media/linkedin.png"),
  // Facebook: require("../Assests/Images/Social-media/facebook.png"),

  Youtube: require("../Assests/Images/Social-media/normal/youtube.png"),
  Twitter: require("../Assests/Images/Social-media/normal/twitter.png"),
  Linked_in: require("../Assests/Images/Social-media/normal/linkedin.png"),
  Facebook: require("../Assests/Images/Social-media/normal/facebook.png"),
};
