import React from "react";
import "../../Core/Styles/Style.css";

function Career() {
    return (
        <div id="">
            <section>
                <div className="container ">
                    <div className="row min-vh-100 justify-content-center align-items-center">
                        <div className="col">
                            <h1 className="career-sub-heading-css mb-5">Apply for the Position</h1>
                            <form>
                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">Name</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>

                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">Email address</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>

                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">Upload CV</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </form>
                        </div>

                    </div>
                </div>
            </section>
        </div>

    );
}

export default Career;
