import React from "react";
// import '../Style.css';
import "../../Core/Styles/Style.css";
import { IoBookSharp } from "react-icons/io5";
import { FaMapLocationDot } from "react-icons/fa6";
import { GiDiamondTrophy } from "react-icons/gi";
import Layout from "../../Components/layout";

function Career() {
  return (
    <div>
      <Layout>
        <div id="careerPage" className="pt-5">
          <section className="career-section-1">
            <div className="container">
              <div className=" row row-cols-lg-2 row-cols-1 justify-content-center align-items-center min-vh-lg-75 text-center">
                <div className="col">

                </div>
                <div className="col career-heading-position-css">
                  <p className="career-section-heading-css ">Join The Team Where Tech Meets Heart</p>
                  <button className="btn button-container">
                    <p className="glass-button "><span>View Jobs</span></p>
                  </button>
                </div>
              </div>
            </div>
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffffff" fill-opacity="1" d="M0,96L30,112C60,128,120,160,180,160C240,160,300,128,360,144C420,160,480,224,540,256C600,288,660,288,720,277.3C780,267,840,245,900,224C960,203,1020,181,1080,192C1140,203,1200,245,1260,261.3C1320,277,1380,267,1410,261.3L1440,256L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path></svg> */}

            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffffff" fill-opacity="1" d="M0,192L0,224L288,224L288,288L576,288L576,224L864,224L864,256L1152,256L1152,224L1440,224L1440,320L1152,320L1152,320L864,320L864,320L576,320L576,320L288,320L288,320L0,320L0,320Z"></path></svg> */}

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0d0d0d" fill-opacity="1" d="M0,192L240,288L480,224L720,320L960,160L1200,192L1440,96L1440,320L1200,320L960,320L720,320L480,320L240,320L0,320Z"></path></svg>
          </section>

          <section className="career-section-2">
            <div class="container-fluid  container-section-css">
              <h1 className="career-sub-heading-css">About Working Here</h1>
              <div class="boxes">
                <div class="box">
                  <GiDiamondTrophy class="icon" />
                  <h2>Shared Success</h2>
                  <p class="piece-count">
                    {" "}
                    The King can move just one square at a time in any
                    direction. It should be defended at all times by the other
                    pieces as once checkmated the game is lost.
                  </p>
                </div>
                <div class="box">
                  <IoBookSharp class="icon" />
                  <h2>Culture of Learning</h2>
                  <p class="piece-count">
                    The most powerful piece on the board, the Queen can move any
                    number of squares in straight lines or on the diagonal until
                    obstructed by another piece.
                  </p>
                </div>
                <div class="box">
                  <FaMapLocationDot class="icon" />

                  <h2>Great Location</h2>
                  <p class="piece-count">
                    After the Queen the Rook is the next most powerful piece on
                    the board. It can move any number of squares in a straight
                    line until obstructed by another piece.
                  </p>
                </div>
              </div>

            </div>
          </section>

          <section className="career-section-3">
            <div className="container col-lg-8 col-10">
              <h1 className="career-sub-heading-css mb-5">Open Positions</h1>
              <div className="row pb-4 mb-4  border-bottom-card-row row-cols-2 career-position-card-css justify-content-center align-items-center ">
                <div className="col">
                  <p className="career-card-heading mb-1">Front-End Developer</p>
                  <p className="career-card-inner-para-css mb-0">Experience:<span> 2+ Years</span></p>
                </div>
                <div className="col text-end">
                  <button className="btn glass-button-2">
                    Apply Now
                  </button>
                </div>
              </div>
              <div className="row pb-4 mb-4  border-bottom-card-row row-cols-2 career-position-card-css justify-content-center align-items-center ">
                <div className="col">
                  <p className="career-card-heading mb-1">Backend Developer</p>
                  <p className="career-card-inner-para-css mb-0">Experience:<span> 2+ Years</span></p>
                </div>
                <div className="col text-end">
                  <button className="btn glass-button-2">
                    Apply Now
                  </button>
                </div>
              </div>
              <div className="row pb-4 mb-4  border-bottom-card-row row-cols-2 career-position-card-css justify-content-center align-items-center ">
                <div className="col">
                  <p className="career-card-heading mb-1">SEO Analyst</p>
                  <p className="career-card-inner-para-css mb-0">Experience:<span> 2+ Years</span></p>
                </div>
                <div className="col text-end">
                  <button className="btn glass-button-2">
                    Apply Now
                  </button>
                </div>
              </div>
              <div className="row pb-4 mb-4  row-cols-2 career-position-card-css justify-content-center align-items-center ">
                <div className="col">
                  <p className="career-card-heading mb-1">ReactNative</p>
                  <p className="career-card-inner-para-css mb-0">Experience:<span> 2+ Years</span></p>
                </div>
                <div className="col text-end">
                  <button className="btn glass-button-2">
                    Apply Now
                  </button>
                </div>
              </div>

            </div>
          </section>




        </div>
      </Layout>
    </div>
  );
}

export default Career;
